<template>
  <span>
    <div class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="dataCadastroInicio">Data Cadastro Inicial</label>
        <Calendar
          id="dataCadastroInicio"
          v-model="v$.dataCadastroInicio.$model"
          :class="{
            'p-invalid': submitted && v$.dataCadastroInicio.$invalid,
          }"
          dateFormat="dd/mm/yy"
          @input="adicionarDataCadastroInicio" />
        <div
          v-if="submitted && v$.dataCadastroInicio.required.$invalid"
          class="p-error">
          O campo data cadastro início é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="dataCadastroFim">Data Cadastro Final</label>
        <Calendar
          id="dataCadastroFim"
          v-model="v$.dataCadastroFim.$model"
          :class="{
            'p-invalid': submitted && v$.dataCadastroFim.$invalid,
          }"
          dateFormat="dd/mm/yy"
          @input="adicionarDataCadastroFim" />
        <div
          v-if="submitted && v$.dataCadastroFim.required.$invalid"
          class="p-error">
          O campo data cadastro fim é obrigatório.
        </div>
      </div>
    </div>
    <div class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="entidade">Entidade</label>
        <MultiSelect
          id="entidade"
          v-model="v$.entidadesSelecionadas.$model"
          optionLabel="nome"
          :options="entidades"
          dataKey="id"
          :filter="true"
          filterPlaceholder="Procure pelo nome da entidade"
          :emptyFilterMessage="'Nenhuma entidade encontrada'"
          placeholder="Selecione uma entidade"
          display="chip"
          @input="adicionarEntidade">
        </MultiSelect>
      
        <div
          v-if="submitted && v$.entidadesSelecionadas.required.$invalid"
          class="p-error">
          O campo entidade é obrigatório.
        </div>
      </div>
      <div v-if="exibirListaConsignataria" class="field col-6 md:col-6">
        <label for="consignataria">Consignatária</label>
        <MultiSelect
          id="consignataria"
          v-model="v$.consignatariasSelecionadas.$model"
          optionLabel="razaoSocial"
          :options="consignatarias"
          dataKey="id"
          :filter="true"
          filterPlaceholder="Procure pelo nome da consignataria"
          :emptyFilterMessage="'Nenhuma consignataria encontrada'"
          placeholder="Selecione uma consignataria"
          display="chip"
          @input="adicionarConsignataria">
          <template #option="slotProps">
            <span>
              {{ slotProps.option.cnpj }} |
              {{ slotProps.option.razaoSocial }}
            </span>
          </template>
        </MultiSelect>
    
        <div
          v-if="submitted && v$.consignatariasSelecionadas.required.$invalid"
          class="p-error">
          O campo consignataria é obrigatório.
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import ConsignatariaService from '@/service/ConsignatariaService.js'
import EntidadeService from '@/service/EntidadeService.js'

export default {
  name: 'LayoutFiltro02',

  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      entidadesSelecionadas: [],
      entidades: [],
      entidadesIdSelecionados: [],
      consignatariasSelecionadas: [],
      consignatarias: [],
      consignatariasIdSelecionados: [],
      dataCadastroInicio: '',
      dataCadastroFim: '',
      exibirListaConsignataria: false,
      parametros: new Map(),
    }
  },

  validations() {
    return {
      dataCadastroInicio: { required },
      dataCadastroFim: { required },
      consignatariasSelecionadas: {
        required: requiredIf(
          this.$auth.hasRoleGestao() || this.$auth.hasRoleGestaoEntidade()
            ? true
            : false,
        ),
      },
      entidadesSelecionadas: { required },
    }
  },

  created() {
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.entidadeService = new EntidadeService(this.$http)
  },

  mounted() {
    this.carregarEntidade()
    this.carregarConsignataria()
  },

  methods: {
    carregarEntidade() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarConsignataria() {
      if (this.$auth.hasRoleGestao() || this.$auth.hasRoleGestaoEntidade()) {
        this.carregarListaConsignatarias()
        this.exibirListaConsignataria = true
      } else {
        this.consignatariasSelecionadas.push(
          JSON.parse(localStorage.getItem('consignatariaSelecionada')),
        )
        this.adicionarConsignataria()
        this.exibirListaConsignataria = false
      }
    },

    carregarListaConsignatarias() {
      if (this.$auth.hasRoleGestao()) {
        this.consignatariaService.getConsignatarias().then((res) => {
          this.consignatarias = res
        })
      } else if (this.$auth.hasRoleGestaoEntidade()) {
        this.consignatariaService
          .getConsignatariasUsuarioGestaoEntidade()
          .then((res) => {
            this.consignatarias = res
          })
      }
    },

    validate() {
      this.v$.dataCadastroInicio.$touch()
      this.v$.dataCadastroFim.$touch()
      this.v$.consignatariasSelecionadas.$touch()
      this.v$.entidadesSelecionadas.$touch()

      return this.v$.dataCadastroInicio.$invalid ||
        this.v$.dataCadastroFim.$invalid ||
        this.v$.consignatariasSelecionadas.$invalid ||
        this.v$.entidadesSelecionadas.$invalid
        ? true
        : false
    },

    adicionarDataCadastroInicio() {
      this.parametros.set(
        'dataCadastroInicio',
        this.converterDataParaDateStylePadrao(
          this.dataCadastroInicio.toLocaleDateString(),
        ),
      )
      this.$emit('parametros', this.parametros)
    },

    adicionarDataCadastroFim() {
      this.parametros.set(
        'dataCadastroFim',
        this.converterDataParaDateStylePadrao(
          this.dataCadastroFim.toLocaleDateString(),
        ),
      )
      this.$emit('parametros', this.parametros)
    },

    converterDataParaDateStylePadrao(data) {
      const [day, month, year] = data.split('/')
      return `${year}-${month}-${day}`
    },

    adicionarEntidade() {
      this.adiconarListaIds('entidade')
      this.parametros.set('entidadeId', this.entidadesIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.entidadesIdSelecionados = []
    },

    adicionarConsignataria() {
      this.adiconarListaIds('consignataria')
      this.parametros.set('consignatariaId', this.consignatariasIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.consignatariasIdSelecionados = []
    },

    adiconarListaIds(tipo) {
      if (tipo == 'entidade') {
        this.entidadesSelecionadas.forEach((valor) =>
          this.entidadesIdSelecionados.push(valor.id.toString()),
        )
      } else if (tipo == 'consignataria') {
        this.consignatariasSelecionadas.forEach((valor) =>
          this.consignatariasIdSelecionados.push(valor.id.toString()),
        )
      }
    },

    limpar() {
      this.entidadesSelecionadas = []
      this.entidadesIdSelecionados = []
      this.consignatariasSelecionadas = []
      this.consignatariasIdSelecionados = []
      this.dataCadastroInicio = ''
      this.dataCadastroFim = ''
    },
  },
}
</script>
